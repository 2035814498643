import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import LadyAntebellumMenu from './_menu.js'

const LadyAntebellum2015TourPage = ({ data }) => (
  <Layout>
    <LadyAntebellumMenu pageName="2015_tour" />
    <div className="textPageDetails">
      <p>
        The "Wheels Up" 2015 Lady Antebellum tour needed six posters created, one for each month of the tour.  The whole series was designed by one artist, giving the set of prints a consistent, illustrated look.
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

LadyAntebellum2015TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default LadyAntebellum2015TourPage

export const pageQuery = graphql`
  query LadyAntebellum2015TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/ladyantebellum/2015_tour/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
