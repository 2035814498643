import React from 'react'
import { Link } from 'gatsby'

const LadyAntebellumMenu = (props) => (
  <div>
  <h2 className="major">Lady Antebellum</h2>
  <ul className="bigList">
  <li className={props.pageName === '2015_tour' ? 'current' : ''}><Link to="/clients/ladyantebellum/2015_tour">2015 US Tour</Link></li>
  </ul>
  </div>
)

export default LadyAntebellumMenu
